import SanityRichText, { SanityRichTextProps } from '@/components/sanity-rich-text';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/shadcn-utils';
import { ComponentProps } from 'react';

type SectionHeaderProps = ComponentProps<'div'> & {
    title?: string | undefined;
    description?: SanityRichTextProps['value'] | undefined;
};

const SectionHeader = ({ title, description = [], className, ...props }: SectionHeaderProps) => (
    <div {...props} className={cn('space-y-3 empty:hidden md:space-y-4', className)}>
        <h2 className="max-w-prose text-2xl font-medium text-foreground lg:text-3xl">{title}</h2>
        {description && <SanityRichText value={description} />}
    </div>
);

const SectionHeaderSkeleton = () => (
    <div className="space-y-3 md:space-y-4">
        <Skeleton className="h-8 w-1/2 lg:h-9 lg:w-1/4" />
        <Skeleton className="h-28 max-w-prose" />
    </div>
);

SectionHeader.Skeleton = SectionHeaderSkeleton;

export default SectionHeader;
