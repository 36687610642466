import { FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';

const NameInput = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
}: UseControllerProps<TFieldValues, TName>) => {
    return (
        <FormField
            control={props.control}
            name={props.name}
            render={({ field }) => (
                <FormItem className="col-span-2 md:col-span-1">
                    <FormLabel>Imię i Nazwisko</FormLabel>
                    <FormControl>
                        <Input
                            type="text"
                            autoComplete="given-name family-name"
                            placeholder="Jan kowalski"
                            {...field}
                        />
                    </FormControl>
                </FormItem>
            )}
        />
    );
};

export default NameInput;
