import { cn } from '@/shadcn-utils';
import { motion, MotionProps, Variants } from 'framer-motion';
import { ComponentProps } from 'react';
import { Skeleton } from '../ui/skeleton';

type ProjectViewerThumbnailProps = ComponentProps<'div'> &
    MotionProps & {
        src: HTMLImageElement['src'];
        alt: HTMLImageElement['alt'];
        isCurrent: boolean;
    };

const ProjectViewerThumbnail = ({
    src,
    alt,
    isCurrent,
    className,
    ...props
}: ProjectViewerThumbnailProps) => {
    const variants: Variants = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 0.25,
        },
        current: {
            opacity: 1,
        },
    };

    return (
        <motion.div
            {...props}
            variants={variants}
            initial="hidden"
            animate={isCurrent ? 'current' : 'visible'}
            whileHover={{ opacity: 1 }}
            className={cn('aspect-square overflow-clip rounded-sm bg-muted', className)}
        >
            <img src={`${src}=s80`} alt={alt} className="size-full object-cover" />
        </motion.div>
    );
};

const ProjectViewerThumbnailSkeleton = () => <Skeleton className="aspect-square" />;

ProjectViewerThumbnail.Skeleton = ProjectViewerThumbnailSkeleton;

export default ProjectViewerThumbnail;
