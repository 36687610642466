import { MediaItem } from '@/models/media-item';
import { useMemo, useState } from 'react';
import ProjectViewerImage from './project-viewer-image';
import ProjectViewerThumbnail from './project-viewer-thumbnail';

type ProjectViewerContentProps = {
    mediaItems: MediaItem[];
};

const ProjectViewerContent = ({ mediaItems }: ProjectViewerContentProps) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const currentMediaItem = useMemo(() => mediaItems[currentIndex], [mediaItems, currentIndex]);

    return (
        <div className="space-y-4">
            <ProjectViewerImage
                src={currentMediaItem.baseUrl}
                alt={currentMediaItem.filename}
                className="w-full"
            />
            <div className="grid grid-cols-12 gap-1">
                {mediaItems.map((item, index) => (
                    <ProjectViewerThumbnail
                        key={item.id}
                        src={item.baseUrl}
                        alt={item.filename}
                        isCurrent={index === currentIndex}
                        className="w-full cursor-pointer"
                        onClick={() => setCurrentIndex(index)}
                    />
                ))}
            </div>
        </div>
    );
};

const ProjectViewerContentSkeleton = () => (
    <div className="space-y-4">
        <ProjectViewerImage.Skeleton />
        <div className="grid grid-cols-12 gap-1">
            <ProjectViewerThumbnail.Skeleton />
            <ProjectViewerThumbnail.Skeleton />
            <ProjectViewerThumbnail.Skeleton />
            <ProjectViewerThumbnail.Skeleton />
            <ProjectViewerThumbnail.Skeleton />
            <ProjectViewerThumbnail.Skeleton />
        </div>
    </div>
);

ProjectViewerContent.Skeleton = ProjectViewerContentSkeleton;

export default ProjectViewerContent;
