import { AppConfig } from './models/app-config';

const appConfig: AppConfig = {
    api: {
        baseUrl: import.meta.env.VITE_API_BASE_URL,
    },
    sanity: {
        projectId: import.meta.env.VITE_SANITY_PROJECT_ID,
        apiVersion: import.meta.env.VITE_SANITY_API_VERSION,
        dataset: import.meta.env.VITE_SANITY_DATASET,
        useCdn: true,
    },
    sentry: {
        dns: import.meta.env.VITE_SENTRY_DNS,
        org: import.meta.env.VITE_SENTRY_ORG,
        project: import.meta.env.VITE_SENTRY_PROJECT,
    },
};

export default appConfig;
