import { useCompany } from '@/hooks/use-company';
import { useHomePage } from '@/hooks/use-home-page';
import { getSanityImageUrl } from '@/utils/get-sanity-image-url';
import isNil from '@/utils/is-nil';

const ContactSectionDetails = () => {
    const { data } = useHomePage();
    const { data: company } = useCompany();

    if (isNil(data) || isNil(company)) {
        return null;
    }

    return (
        <div className="flex flex-col items-center gap-y-10">
            {data.contact?.owner?.avatar ? (
                <div className="size-32 overflow-clip rounded-full border-primary-foreground bg-primary transition-all duration-300 lg:size-40">
                    <div
                        className="size-full bg-cover"
                        style={{
                            backgroundImage: `url('${getSanityImageUrl(data.contact.owner.avatar)}')`,
                        }}
                    ></div>
                </div>
            ) : null}
            <div className="space-y-3">
                <div className="text-center text-lg font-semibold text-primary-foreground">
                    {data.contact?.owner?.name}
                </div>
                <div className="flex flex-col gap-1 text-center text-sm text-primary-foreground/80">
                    <a href={`mailto:${company.contact?.email}`}>{company.contact?.email}</a>
                    <a href={`tel:${company.contact?.phone}`}>{company.contact?.phone}</a>
                </div>
            </div>
        </div>
    );
};

export default ContactSectionDetails;
