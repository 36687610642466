import { cn } from '@/shadcn-utils';
import isNil from '@/utils/is-nil';
import { ImageIcon, LoaderIcon } from 'lucide-react';
import { ComponentPropsWithoutRef, useEffect, useState } from 'react';

type PreloadedImageProps = ComponentPropsWithoutRef<'div'> & {
    src: string;
    alt?: string;
    scaleDown?: boolean;
};

const PreloadedImage = ({
    src,
    alt,
    scaleDown = true,
    className,
    ...props
}: PreloadedImageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadedImage, setLoadedImage] = useState<string>('');
    const [error, setError] = useState<null | string | Event>(null);

    const loadImage = (url: string): Promise<string> =>
        new Promise((res, rej) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                setError(null);
                res(url);
            };
            img.onerror = (error) => {
                setError(error);
                rej(error);
            };
        });

    const preloadImage = async () => {
        setIsLoading(true);
        await loadImage(src);
        setLoadedImage(src);
        setIsLoading(false);
    };

    useEffect(() => {
        preloadImage();
    }, [src]);

    if (isLoading) {
        return (
            <div
                {...props}
                className={cn(
                    'flex size-full min-h-10 min-w-10 items-center justify-center overflow-clip bg-muted',
                    className,
                )}
            >
                <span className="text-sm font-semibold text-muted-foreground">
                    <LoaderIcon size={16} strokeWidth={1.25} className="animate-spin" />
                </span>
            </div>
        );
    }

    if (!isNil(error)) {
        return (
            <div
                {...props}
                className={cn(
                    'flex size-full min-h-10 min-w-10 items-center justify-center overflow-clip bg-muted',
                    className,
                )}
            >
                <span className="text-sm font-semibold text-muted-foreground opacity-25">
                    <ImageIcon size={24} />
                </span>
            </div>
        );
    }

    return (
        <div {...props} className={cn('size-full min-h-10 min-w-10 bg-muted', className)}>
            <img
                className={cn(
                    'size-full select-none',
                    scaleDown ? 'object-scale-down' : 'object-cover',
                )}
                alt={alt}
                src={loadedImage}
            />
        </div>
    );
};

export default PreloadedImage;
