import ProjectViewer from '@/components/project-viewer/project-viewer';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Skeleton } from '@/components/ui/skeleton';
import { Album } from '@/models/album';
import { cn } from '@/shadcn-utils';
import { motion } from 'framer-motion';
import { ComponentProps } from 'react';

type AlbumCardProps = ComponentProps<'div'> & {
    album: Album;
};

const AlbumCard = ({ album, className, ...props }: AlbumCardProps) => {
    return (
        <div {...props} className={cn('space-y-2', className)}>
            <Dialog>
                <DialogTrigger asChild>
                    <div className="relative aspect-[5/4] cursor-pointer overflow-clip rounded bg-muted">
                        <motion.img
                            initial={{ scale: 1 }}
                            animate={{
                                scale: 1,
                                transition: {
                                    type: 'tween',
                                    duration: 0.25,
                                },
                            }}
                            whileHover={{
                                scale: 1.1,
                                transition: {
                                    type: 'tween',
                                    duration: 0.25,
                                },
                            }}
                            src={album.coverPhotoBaseUrl}
                            alt={album.title}
                            className="size-full object-cover"
                        />
                    </div>
                </DialogTrigger>
                <DialogContent aria-describedby={undefined}>
                    <DialogHeader>
                        <DialogTitle>{album.title}</DialogTitle>
                    </DialogHeader>
                    <ProjectViewer albumId={album.id} />
                </DialogContent>
            </Dialog>
            <div>
                <div className="text-md font-medium">{album.title}</div>
                <div className="text-sm text-muted-foreground">{album.mediaItemsCount} zdjęć</div>
            </div>
        </div>
    );
};

const AlbumCardSkeleton = () => (
    <div className="space-y-2">
        <Skeleton className="aspect-[5/4]" />
        <div className="space-y-1">
            <Skeleton className="h-6 w-2/3" />
            <Skeleton className="h-5 w-1/3" />
        </div>
    </div>
);

AlbumCard.Skeleton = AlbumCardSkeleton;

export default AlbumCard;
