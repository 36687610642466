import { PortableText, PortableTextProps } from '@portabletext/react';
import { ComponentPropsWithoutRef } from 'react';
import Paragraph from './paragraph';

export type SanityRichTextProps = PortableTextProps &
    Pick<ComponentPropsWithoutRef<'div'>, 'className'>;

const SanityRichText = ({ className, components, ...props }: SanityRichTextProps) => {
    return (
        <PortableText
            {...props}
            components={{
                block: {
                    normal: ({ children }) => (
                        <Paragraph className={className}>{children}</Paragraph>
                    ),
                },
                list: ({ children }) => (
                    <ul className="ml-6 list-outside list-disc text-muted-foreground">
                        {children}
                    </ul>
                ),
                ...components,
            }}
        />
    );
};

export default SanityRichText;
