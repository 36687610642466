import './index.css';

import AppWrapper from '@/app-wrapper';
import Loader from '@/components/loader';
import { Toaster } from '@/components/ui/toaster';
import HomePage from '@/pages/home/home';
import ProjectsPage from '@/pages/projects/projects';
import UnavailablePage from '@/pages/unavailable';
import { store } from '@/store/store';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { initializeSentry } from './setup-sentry';

// Error logging
initializeSentry();

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppWrapper />,
        children: [
            {
                path: '/',
                element: <HomePage />,
            },
            {
                path: '/projects',
                element: <ProjectsPage />,
            },
            {
                path: '/unavailable',
                element: <UnavailablePage />,
            },
            {
                path: '*',
                element: <Navigate to="/" />,
            },
        ],
    },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <RouterProvider router={router} />
            <div className="sr-only">
                architekt wnętrz Radom, Warszawa, Kielce, Lublin, Kraków, projektant wnętrz, projekt
                wnętrza, studio projektowania wnętrz, biuro projektowe, wizualizacje wnętrz
            </div>
            <Loader />
            <Toaster />
        </ReduxProvider>
    </React.StrictMode>,
);
