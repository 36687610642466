import Logo from '@/components/logo';
import { useSelector } from '@/hooks/use-selector';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';

const Loader = () => {
    const isLoading = useSelector((state) => state.ui.isLoading);

    useEffect(() => {
        document.documentElement.style.overflow = isLoading ? 'clip' : 'auto';
    }, [isLoading]);

    return (
        <AnimatePresence>
            {isLoading && (
                <motion.div
                    initial={{ translateY: '0' }}
                    exit={{ translateY: '-100%' }}
                    transition={{ duration: 0.5, delay: 0.3 }}
                    className="fixed inset-0 z-30 flex items-center justify-center bg-primary"
                >
                    <Logo />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Loader;
