import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';
import FooterCompanyInfo from './footer-company-info';

type FooterProps = ComponentPropsWithoutRef<'footer'>;

const Footer = ({ className, ...props }: FooterProps) => {
    const year = new Date().getFullYear();

    return (
        <footer
            className={cn('relative overflow-clip bg-primary text-primary-foreground', className)}
            {...props}
        >
            <div className="container space-y-10 py-10">
                <FooterCompanyInfo />
                <div className="col-span-full h-px border-t border-border/10"></div>
                <div className="flex flex-col justify-between gap-x-10 gap-y-2 lg:flex-row">
                    <div className="text-sm text-muted-foreground">
                        Copyright © {year}. All Rights Reserved.
                    </div>
                    <div className="space-x-1.5 text-sm text-primary-foreground">
                        <span className="text-muted-foreground">Stworzono przez</span>
                        <a href="https://www.linkedin.com/in/popkacper/" target="_blank">
                            <span className="font-semibold">Codesign</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
