import PageContainer from '@/components/page-container';
import SectionHeader from '@/components/section/section-header';
import SectionParagraph from '@/components/section/section-paragraph';
import SectionTitle from '@/components/section/section-title';
import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';

type SectionProps = ComponentPropsWithoutRef<'div'> & {
    variant?: 'light' | 'dark';
};

const Section = ({ variant = 'light', children, id, ...props }: SectionProps) => {
    return (
        <section
            id={id}
            className={cn(
                'py-10',
                variant === 'light' && 'bg-background',
                variant === 'dark' && 'bg-primary',
            )}
        >
            <PageContainer {...props}>{children}</PageContainer>
        </section>
    );
};

Section.Title = SectionTitle;
Section.Paragraph = SectionParagraph;
Section.Header = SectionHeader;

export default Section;
