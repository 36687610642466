import isNil from "@/utils/is-nil";

const useScrollToElement = () => {
    const scrollTo = (id: string, behavior: ScrollBehavior = 'smooth') => {
        const navElement = document.getElementById('navbar');
        const targetElement = document.getElementById(id);

        if (isNil(targetElement)) {
            return;
        }

        const navElementRect = navElement?.getBoundingClientRect();
        const targetElementRect = targetElement.getBoundingClientRect();

        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const topOffset = navElementRect?.height || 0;
        const top = targetElementRect.top + scrollTop - topOffset;

        window.scrollTo({
            top,
            left: 0,
            behavior,
        });
    }

    return { scrollTo };
};

export default useScrollToElement;
