import PricingCard from '@/components/pricing-card';
import { useHomePage } from '@/hooks/use-home-page';
import { PricingTier } from '@/types/sanity.types';
import isNil from '@/utils/is-nil';
import { motion, Variants } from 'framer-motion';

const PricingSectionTiers = () => {
    const { data } = useHomePage();

    const animationVariants: Variants = {
        initial: {
            opacity: 0,
            transform: 'translateY(25%)',
        },
        animate: (index) => ({
            opacity: 1,
            transform: 'translateY(0)',
            transition: {
                delay: 0.05 * index,
            },
        }),
    };

    if (isNil(data)) {
        return null;
    }

    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
            {data.pricing?.tiers?.map((tier, index) => (
                <motion.div
                    key={index}
                    variants={animationVariants}
                    initial="initial"
                    whileInView="animate"
                    viewport={{ once: true }}
                    custom={index}
                >
                    <PricingCard tier={tier as unknown as PricingTier} className="min-h-full" />
                </motion.div>
            ))}
        </div>
    );
};

export default PricingSectionTiers;
