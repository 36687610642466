import ProjectsGrid from '@/components/projects-grid/projects-grid';
import Section from '@/components/section/section';
import { Button } from '@/components/ui/button';
import { useAlbums } from '@/hooks/use-albums';
import { useHomePage } from '@/hooks/use-home-page';
import { useNavigate } from 'react-router-dom';
import ProjectsSectionHeader from './projects-section-header';

const ProjectsSection = () => {
    const { data: page } = useHomePage();
    const { data: albums } = useAlbums(6);
    const navigate = useNavigate();

    return (
        <Section id="projects">
            <div className="flex flex-col gap-6">
                <ProjectsSectionHeader />
                {page?.projects?.button && (
                    <Button
                        type="button"
                        size="lg"
                        variant="outline"
                        className="w-fit"
                        onClick={() => navigate('/projects')}
                    >
                        {page.projects.button}
                    </Button>
                )}
            </div>
            {albums ? <ProjectsGrid albums={albums} /> : null}
        </Section>
    );
};

export default ProjectsSection;
