import appConfig from '@/app-config';
import axios from 'axios';

const httpClient = axios.create({
    baseURL: appConfig.api.baseUrl,
});

export const EmpeApiClient = {
    get: async <TResult = unknown>(url: string) => {
        const res = await httpClient.get<TResult>(url);

        return res.data;
    },
    post: async <TPayload, TResult = unknown>(url: string, payload: TPayload) => {
        const res = await httpClient.post<TResult>(url, payload);

        return res.data;
    },
    patch: async <TPayload, TResult = unknown>(url: string, payload: TPayload) => {
        const res = await httpClient.patch<TResult>(url, payload);

        return res.data;
    },
    put: async <TPayload, TResult = unknown>(url: string, payload: TPayload) => {
        const res = await httpClient.put<TResult>(url, payload);

        return res.data;
    },
    delete: async <TResult = unknown>(url: string) => {
        const res = await httpClient.delete<TResult>(url);

        return res.data;
    },
};
