import ProjectsGrid from '@/components/projects-grid/projects-grid';
import SectionHeader from '@/components/section-header/section-header';
import Section from '@/components/section/section';
import { Button } from '@/components/ui/button';
import { useAlbums } from '@/hooks/use-albums';
import { useGalleryPage } from '@/hooks/use-gallery-page';
import SecondaryLayout from '@/layouts/secodary-layout';
import isNil from '@/utils/is-nil';
import { NavLink } from 'react-router-dom';

const ProjectsPage = () => {
    const { data: page, isLoading: isLoadingPage } = useGalleryPage();
    const { data: albums, isLoading: isLoadingAlbums } = useAlbums(50);

    const isLoading = isLoadingPage || isLoadingAlbums;
    const isEmpty = isNil(page) || isNil(albums);

    return (
        <SecondaryLayout>
            <Section className="min-h-screen">
                <NavLink to="/" className="inline-block w-fit">
                    <Button type="button" size="lg">
                        Wróć na stronę główną
                    </Button>
                </NavLink>
                {isLoading && (
                    <>
                        <SectionHeader.Skeleton />
                        <ProjectsGrid.Skeleton />
                    </>
                )}
                {!isEmpty && (
                    <>
                        <SectionHeader title={page.title} description={page.description} />
                        <ProjectsGrid albums={albums} />
                    </>
                )}
            </Section>
        </SecondaryLayout>
    );
};

export default ProjectsPage;
