import ProjectsGridItem from '@/components/projects-grid/projects-grid-item';
import { Album } from '@/models/album';
import { motion, Variants } from 'framer-motion';

type ProjectsGridProps = {
    albums: Album[];
};

const ProjectsGrid = ({ albums }: ProjectsGridProps) => {
    const variants: Variants = {
        visible: {
            transition: {
                staggerChildren: 0.05,
            },
        },
    };

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants}
            className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3"
        >
            {albums.map((album) => (
                <ProjectsGridItem key={album.id} album={album} />
            ))}
        </motion.div>
    );
};

const ProjectsGridSkeleton = () => (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        <ProjectsGridItem.Skeleton />
        <ProjectsGridItem.Skeleton />
        <ProjectsGridItem.Skeleton />
        <ProjectsGridItem.Skeleton />
        <ProjectsGridItem.Skeleton />
        <ProjectsGridItem.Skeleton />
    </div>
);

ProjectsGrid.Skeleton = ProjectsGridSkeleton;

export default ProjectsGrid;
