import Review from '@/components/reviews/review';
import ReviewsSlider from '@/components/reviews/review-slider';
import Section from '@/components/section/section';
import { useReviews } from '@/hooks/use-reviews';
import { cn } from '@/shadcn-utils';
import isNil from '@/utils/is-nil';

const ReviewsSection = () => {
    const { data: reviews } = useReviews();

    return (
        <Section id="reviews" className={cn(isNil(reviews) && 'hidden')}>
            <div className="hidden lg:block">
                {reviews ? <ReviewsSlider reviews={reviews} /> : null}
            </div>
            <div className="block space-y-2 lg:hidden">
                {reviews?.map((review, index) => <Review key={index} review={review} />)}
            </div>
        </Section>
    );
};

export default ReviewsSection;
