import Footer from '@/components/footer/footer';
import Navbar from '@/components/navbar/navbar';
import { useCompany } from '@/hooks/use-company';
import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';

type DefaultLayoutProps = ComponentPropsWithoutRef<'div'>;

const DefaultLayout = ({ children, className, ...props }: DefaultLayoutProps) => {
    useCompany();

    return (
        <div className={cn('relative min-h-dvh', className)} {...props}>
            <Navbar />
            <div className="flex-1">{children}</div>
            <Footer />
        </div>
    );
};

export default DefaultLayout;
