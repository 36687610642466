import Review from '@/components/reviews/review';
import { Review as ReviewType } from '@/models/review';
import { motion, Variants } from 'framer-motion';
import Marquee from 'react-fast-marquee';

type ReviewsSliderProps = {
    reviews: ReviewType[];
};

const ReviewsSlider = ({ reviews }: ReviewsSliderProps) => {
    const animationVariants: Variants = {
        initial: {
            opacity: 0,
            transform: 'translateY(25%)',
        },
        animate: {
            opacity: 1,
            transform: 'translateY(0)',
        },
    };

    return (
        <motion.div
            variants={animationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
        >
            <Marquee pauseOnHover={true} gradient={true} gradientWidth={100}>
                {reviews.map((review, index) => (
                    <Review key={index} review={review} className="mx-2 w-[512px]" />
                ))}
            </Marquee>
        </motion.div>
    );
};

export default ReviewsSlider;
