import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';

type SectionHeaderType = ComponentPropsWithoutRef<'div'>;

const SectionHeader = ({ children, className, ...props }: SectionHeaderType) => (
    <div className={cn('space-y-3 md:space-y-4', className)} {...props}>
        {children}
    </div>
);

export default SectionHeader;
