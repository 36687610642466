import NavbarMenuDesktop from '@/components/navbar/navbar-menu-desktop';
import NavbarMenuMobile from '@/components/navbar/navbar-menu-mobile';
import { NavbarAction } from '@/types/navbar-actions';

const Navbar = () => {
    const navbarButtons: NavbarAction[] = [
        {
            elementId: 'about',
            label: 'O Firmie',
            type: 'default',
        },
        {
            elementId: 'reviews',
            label: 'Wasze Opinie',
            type: 'default',
        },
        {
            elementId: 'projects',
            label: 'Moje Projekty',
            type: 'default',
        },
        {
            elementId: 'pricing',
            label: 'Moja Oferta',
            type: 'default',
        },
        {
            elementId: 'contact',
            label: 'Skontaktuj się ze mną',
            type: 'highlighted',
        },
    ];

    return (
        <>
            <NavbarMenuDesktop actions={navbarButtons} />
            <NavbarMenuMobile actions={navbarButtons} />
        </>
    );
};

export default Navbar;
