import Paragraph from '@/components/paragraph';
import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';

type SectionParagraphType = ComponentPropsWithoutRef<'p'>;

const SectionParagraph = ({ children, className, ...props }: SectionParagraphType) => (
    <Paragraph className={cn('text-muted-foreground', className)} {...props}>
        {children}
    </Paragraph>
);

export default SectionParagraph;
