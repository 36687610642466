import Logo from '@/components/logo';
import NavbarButton from '@/components/navbar/navbar-button';
import PageContainer from '@/components/page-container';
import useScrollToElement from '@/hooks/use-scroll-to-element';
import { cn } from '@/shadcn-utils';
import { NavbarAction } from '@/types/navbar-actions';
import isNil from '@/utils/is-nil';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import { useState } from 'react';

type NavbarState = 'default' | 'scrolled';

type NavbarMenuDesktopProps = {
    actions: NavbarAction[];
};

const NavbarMenuDesktop = ({ actions }: NavbarMenuDesktopProps) => {
    const groupedActions = actions.reduce<Map<string, NavbarAction[]>>((groups, item) => {
        const group = groups.get(item.type);

        if (isNil(group)) {
            groups.set(item.type, [item]);
        } else {
            group.push(item);
        }

        return groups;
    }, new Map([]));

    const [state, setState] = useState<NavbarState>('default');
    const { scrollTo } = useScrollToElement();
    const { scrollY } = useScroll();

    useMotionValueEvent(scrollY, 'change', (value) => {
        setState(value > 0 ? 'scrolled' : 'default');
    });

    return (
        <nav
            id="navbar"
            className={cn(
                'fixed left-0 right-0 top-0 z-10 hidden select-none transition-all duration-300 lg:block',
                state === 'scrolled' ? 'bg-primary py-6' : 'py-10',
            )}
        >
            <PageContainer className="flex-row items-center justify-between">
                <button type="button" onClick={() => scrollTo('root')}>
                    <Logo
                        className={cn('transition-all duration-300')}
                        size={state === 'default' ? 64 : 48}
                    />
                </button>
                <div className="hidden gap-4 lg:flex lg:items-center">
                    {[...groupedActions.values()].map((group, index) => (
                        <div
                            key={index}
                            className="flex items-center gap-1 rounded-lg bg-primary/60 p-1 backdrop-blur"
                        >
                            {group.map((action) => (
                                <NavbarButton
                                    key={action.elementId}
                                    sectionId={action.elementId}
                                    text={action.label}
                                    variant={action.type}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </PageContainer>
        </nav>
    );
};

export default NavbarMenuDesktop;
