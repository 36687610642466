import SanityRichText from '@/components/sanity-rich-text';
import Section from '@/components/section/section';
import { useHomePage } from '@/hooks/use-home-page';
import isNil from '@/utils/is-nil';

const ContactSectionHeader = () => {
    const { data } = useHomePage();

    if (isNil(data)) {
        return null;
    }

    return (
        <Section.Header>
            <Section.Title>{data.contact?.title}</Section.Title>
            <SanityRichText
                value={data.contact?.description || []}
                components={{
                    block: {
                        normal: ({ children }) => <Section.Paragraph>{children}</Section.Paragraph>,
                    },
                }}
            />
        </Section.Header>
    );
};

export default ContactSectionHeader;
