import { MotionContactForm } from '@/components/contact-form/contact-form';
import { MotionContactFormMessage } from '@/components/contact-form/contact-form-message';
import Section from '@/components/section/section';
import { AnimatePresence, Variants } from 'framer-motion';
import { useCallback, useState } from 'react';
import ContactSectionDetails from './contact-section-details';
import ContactSectionHeader from './contact-section-header';

const ContactSection = () => {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const onFormSubmit = useCallback(() => {
        setIsSubmitted(true);
    }, []);

    const variants: Variants = {
        initial: {
            y: '25%',
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
        },
        exit: {
            y: '-25%',
            opacity: 0,
        },
    };

    return (
        <Section id="contact">
            <ContactSectionHeader />
            <div className="grid animate-bg-scroll grid-cols-1 overflow-clip rounded-xl bg-primary bg-pattern-topography p-1 lg:grid-cols-[1fr_40%] lg:p-4">
                <div className="overflow-clip rounded-sm bg-card p-6 lg:p-10">
                    <AnimatePresence mode="wait">
                        {isSubmitted ? (
                            <MotionContactFormMessage
                                key="message"
                                variants={variants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                className="size-full"
                                onSendAnother={() => setIsSubmitted(false)}
                            />
                        ) : (
                            <MotionContactForm
                                key="form"
                                variants={variants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                onSubmitSuccess={onFormSubmit}
                            />
                        )}
                    </AnimatePresence>
                </div>
                <div className="flex items-center justify-center p-14 lg:p-20">
                    <ContactSectionDetails />
                </div>
            </div>
        </Section>
    );
};

export default ContactSection;
