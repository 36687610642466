import { useHomePage } from '@/hooks/use-home-page';
import isNil from '@/utils/is-nil';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UnavailablePage = () => {
    const navigate = useNavigate();
    const { data } = useHomePage();

    const handleOnClick = () => {
        navigate('/');
    };

    useEffect(() => {
        if (isNil(data)) {
            return;
        }

        handleOnClick();
    }, [data]);

    return (
        <div className="flex h-dvh flex-col items-center justify-center gap-10 bg-black p-10">
            <div className="flex max-w-md flex-col items-center justify-center gap-4 text-center">
                <h1 className="text-2xl uppercase text-white">Strona chwilowo niedostępna</h1>
                <p className="text-base text-muted-foreground">
                    Trwają pracę nad przywróceniem strony. Przepraszamy za niedogodności. Przeładuj
                    stronę aby spróbować ponownie.
                </p>
            </div>
        </div>
    );
};

export default UnavailablePage;
