import PreloadedImage from '@/components/preloaded-image';
import Section from '@/components/section/section';
import { useHomePage } from '@/hooks/use-home-page';
import { getSanityImageUrl } from '@/utils/get-sanity-image-url';
import AboutSectionHeader from './about-section-header';

const AboutSection = () => {
    const { data } = useHomePage();

    return (
        <Section id="about">
            <div className="flex flex-col items-center justify-between gap-10 rounded bg-background lg:flex-row">
                <AboutSectionHeader />
                {data?.about?.image && (
                    <PreloadedImage
                        src={getSanityImageUrl(data?.about.image).url()}
                        scaleDown={false}
                        className="aspect-video h-[initial] max-w-[512px] overflow-clip rounded"
                    />
                )}
            </div>
        </Section>
    );
};

export default AboutSection;
