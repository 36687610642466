import { SanityCMSService } from "@/services/sanity-cms";
import { uiActions } from "@/store/general-slice";
import useSWR from "swr";
import { useDispatch } from "./use-dispatch";

export const useGalleryPage = () => {
    const dispatch = useDispatch();

    return useSWR('gallery-page', SanityCMSService.getGalleryPage, {
        dedupingInterval: 1800000,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: () => dispatch(uiActions.setIsLoading(false)),
        onError: () => dispatch(uiActions.setIsLoading(false)),
        onLoadingSlow: () => dispatch(uiActions.setIsLoading(true)),
    });
};
