import PageContainer from '@/components/page-container';
import SanityRichText from '@/components/sanity-rich-text';
import { Button } from '@/components/ui/button';
import { useHomePage } from '@/hooks/use-home-page';
import useScrollToElement from '@/hooks/use-scroll-to-element';
import { cn } from '@/shadcn-utils';
import { getSanityImageUrl } from '@/utils/get-sanity-image-url';
import isNil from '@/utils/is-nil';
import { motion } from 'framer-motion';
import { ArrowDownIcon } from 'lucide-react';
import { ComponentPropsWithoutRef } from 'react';

type HeroSectionProps = ComponentPropsWithoutRef<'section'>;

const HeroSection = ({ className, ...props }: HeroSectionProps) => {
    const { scrollTo } = useScrollToElement();
    const { data } = useHomePage();

    if (isNil(data)) {
        return null;
    }

    return (
        <section
            id="hero"
            className={cn(
                'flex h-dvh animate-bg-scroll-x overflow-clip bg-cover bg-center bg-no-repeat',
                className,
            )}
            style={{
                backgroundImage: data.hero?.image && `url(${getSanityImageUrl(data.hero.image)})`,
            }}
            {...props}
        >
            <div className={cn(`relative flex size-full items-end bg-black/80 lg:items-center`)}>
                <PageContainer className="text-white">
                    <div className="flex flex-col gap-6 lg:items-center lg:text-center">
                        {data ? (
                            <>
                                <h1 className="text-4xl/[1.15] font-medium capitalize lg:text-6xl/[1.25]">
                                    {data.hero?.title}
                                </h1>
                                {data.hero?.description ? (
                                    <SanityRichText
                                        value={data.hero.description}
                                        className="text-white/60"
                                    />
                                ) : null}
                                <Button
                                    type="button"
                                    variant="outline"
                                    size="xl"
                                    onClick={() => scrollTo('projects')}
                                    className="mb-20 mt-6 hover:bg-white lg:-mb-16"
                                >
                                    {data.hero?.buttonLabel}
                                </Button>
                            </>
                        ) : null}
                        <motion.div
                            animate={{
                                y: [0, '100%', 0],
                                opacity: [0.25, 1, 0.25],
                            }}
                            transition={{
                                duration: 2,
                                repeat: Infinity,
                                ease: 'easeInOut',
                            }}
                            className="absolute bottom-20 hidden lg:block"
                        >
                            <ArrowDownIcon />
                        </motion.div>
                    </div>
                </PageContainer>
            </div>
        </section>
    );
};

export default HeroSection;
