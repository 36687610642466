import AlbumCard from '@/components/album-card';
import { Album } from '@/models/album';
import { motion, MotionProps, Variants } from 'framer-motion';
import { ComponentProps } from 'react';

type ProjectsGridItemProps = MotionProps &
    ComponentProps<'div'> & {
        album: Album;
    };

const ProjectsGridItem = ({ album, className, ...props }: ProjectsGridItemProps) => {
    const variants: Variants = {
        hidden: {
            opacity: 0,
            y: 80,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.25,
            },
        },
    };

    return (
        <motion.div {...props} variants={variants} className={className}>
            <AlbumCard album={album} />
        </motion.div>
    );
};

ProjectsGridItem.Skeleton = AlbumCard.Skeleton;

export default ProjectsGridItem;
