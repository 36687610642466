import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';

type SectionTitleType = ComponentPropsWithoutRef<'h2'>;

const SectionTitle = ({ children, className, ...props }: SectionTitleType) => (
    <h2
        className={cn('max-w-prose text-2xl font-medium text-foreground lg:text-3xl', className)}
        {...props}
    >
        {children}
    </h2>
);

export default SectionTitle;
