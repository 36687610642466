import { cn } from '@/shadcn-utils';
import { LoaderIcon } from 'lucide-react';
import { ComponentProps, ReactEventHandler, useEffect, useState } from 'react';
import { Skeleton } from '../ui/skeleton';

type ProjectViewerImageProps = ComponentProps<'div'> & {
    src: HTMLImageElement['src'];
    alt: HTMLImageElement['alt'];
};

const ProjectViewerImage = ({ src, alt, className, ...props }: ProjectViewerImageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleOnLoad: ReactEventHandler<HTMLImageElement> = (e) => {
        setIsLoading(false);
        props.onLoad?.(e);
    };

    const handleOnError: ReactEventHandler<HTMLImageElement> = (e) => {
        setIsLoading(false);
        props.onError?.(e);
    };

    useEffect(() => {
        setIsLoading(true);
    }, [src]);

    return (
        <div
            {...props}
            className={cn('relative aspect-[5/4] overflow-clip rounded bg-muted', className)}
        >
            {isLoading ? (
                <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/80">
                    <LoaderIcon size={16} className="animate-spin text-white" />
                </div>
            ) : null}
            <img
                src={`${src}=s800`}
                alt={alt}
                onLoad={handleOnLoad}
                onError={handleOnError}
                className="size-full object-contain"
            />
        </div>
    );
};

const ProjectViewerImageSkeleton = () => <Skeleton className="aspect-[5/4]" />;

ProjectViewerImage.Skeleton = ProjectViewerImageSkeleton;

export default ProjectViewerImage;
