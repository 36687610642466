import Footer from '@/components/footer/footer';
import ScrollUpButton from '@/components/scroll-up-button';
import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';

type SecondaryLayoutProps = ComponentPropsWithoutRef<'div'>;

const SecondaryLayout = ({ children, className, ...props }: SecondaryLayoutProps) => {
    return (
        <div className={cn('relative min-h-dvh', className)} {...props}>
            <div className="flex-1">{children}</div>
            <ScrollUpButton />
            <Footer />
        </div>
    );
};

export default SecondaryLayout;
