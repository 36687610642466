import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';

type LogoProps = ComponentPropsWithoutRef<'svg'> & {
    size?: number;
};

const Logo = ({ size = 64, fill = 'white', className, ...props }: LogoProps) => (
    <svg
        {...props}
        width={size}
        height={size}
        fill={fill}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        className={cn('', className)}
    >
        <path d="M6.09779 35.6037V37.9591H2.56954V42.7283H7.00783V45.0643H0V28.7126H7.00783V31.0486H2.56954V35.6037H6.09779Z" />
        <path d="M19.364 45.0643V28.7126H15.792L14.04 40.3194L12.1469 28.7126H8.57486V45.0643H10.8135V33.5013L12.7309 45.0643H15.1593L16.9356 33.3359V45.0643" />
        <path d="M27.8902 29.7395C28.5391 30.4273 28.8635 31.4314 28.8635 32.7519V34.8786C28.8635 36.2023 28.5391 37.2064 27.8902 37.891C27.2413 38.5755 26.2842 38.9227 25.0189 38.9324H23.8023V45.0643H21.2328V28.7126H25.0189C26.294 28.7126 27.2511 29.0549 27.8902 29.7395ZM23.8023 36.5819H25.0189C25.1923 36.5949 25.3663 36.5703 25.5293 36.5098C25.6922 36.4494 25.8402 36.3544 25.963 36.2315C26.2079 35.8848 26.3202 35.4617 26.2794 35.0392V32.6059C26.3197 32.1834 26.2075 31.7605 25.963 31.4136C25.8402 31.2906 25.6922 31.1957 25.5293 31.1352C25.3663 31.0747 25.1923 31.0502 25.0189 31.0632H23.8023V36.5819Z" />
        <path d="M36.2509 35.6037V37.9591H32.7275V42.7283H37.1658V45.0643H30.1726V28.7126H37.1804V31.0486H32.7275V35.6037H36.2509Z" />
        <path d="M1.12417 47.7165V54.8704H0V47.7165H1.12417Z" />
        <path d="M3.67911 54.8704H2.66687V47.7165H4.07817L5.23154 51.9991V47.7165H6.23405V54.8704H5.08068L3.67911 49.6875V54.8704Z" />
        <path d="M10.9254 47.7165V48.7385H9.7331V54.8704H8.62353V48.7385H7.45069V47.7165H10.9254Z" />
        <path d="M14.8186 50.7289V51.7509H13.2759V53.8484H15.2226V54.8704H12.1664V47.7165H15.2323V48.7385H13.2857V50.7289H14.8186Z" />
        <path d="M18.9163 54.5394C18.9055 54.3563 18.9055 54.1727 18.9163 53.9895V52.8653C18.9381 52.6187 18.8764 52.3719 18.7411 52.1646C18.6698 52.0894 18.5824 52.0316 18.4854 51.9953C18.3884 51.959 18.2845 51.9454 18.1814 51.9553H17.7921V54.8752H16.6485V47.7165H18.342C18.57 47.6958 18.7998 47.7209 19.0179 47.7903C19.236 47.8596 19.4381 47.9719 19.6122 48.1204C19.9036 48.4653 20.047 48.9113 20.0113 49.3614V49.9211C20.0479 50.2105 19.9971 50.5042 19.8652 50.7645C19.7334 51.0247 19.5266 51.2394 19.2715 51.381C19.5282 51.4812 19.7346 51.6789 19.8458 51.931C19.973 52.2392 20.0327 52.571 20.021 52.9043V54.0187C20.0124 54.1808 20.0124 54.3433 20.021 54.5054C20.0367 54.6346 20.0711 54.7608 20.1232 54.8801H18.9795C18.9459 54.7692 18.9247 54.655 18.9163 54.5394ZM17.7726 50.9333H18.2106C18.2993 50.9377 18.388 50.9245 18.4715 50.8944C18.555 50.8644 18.6318 50.818 18.6973 50.7581C18.8268 50.5943 18.8878 50.3868 18.8676 50.179V49.4733C18.8833 49.2748 18.8337 49.0766 18.7265 48.9088C18.6729 48.8482 18.6057 48.8011 18.5304 48.7715C18.4551 48.7419 18.3739 48.7306 18.2934 48.7385H17.7726V50.9333Z" />
        <path d="M22.6149 47.7165V54.8704H21.4907V47.7165H22.6149Z" />
        <path d="M24.5177 48.101C24.8703 47.7994 25.319 47.6337 25.783 47.6337C26.247 47.6337 26.6957 47.7994 27.0483 48.101C27.363 48.4694 27.5218 48.946 27.4911 49.4296V53.1525C27.5218 53.636 27.363 54.1126 27.0483 54.481C26.6957 54.7826 26.247 54.9483 25.783 54.9483C25.319 54.9483 24.8703 54.7826 24.5177 54.481C24.2052 54.1113 24.0467 53.6357 24.0748 53.1525V49.4296C24.0467 48.9463 24.2052 48.4707 24.5177 48.101ZM25.783 53.9408C26.1723 53.9408 26.367 53.7073 26.367 53.2401V49.376C26.367 48.8894 26.1723 48.6704 25.783 48.6704C25.3937 48.6704 25.199 48.904 25.199 49.376V53.2401C25.199 53.6975 25.3969 53.9262 25.7927 53.9262L25.783 53.9408Z" />
        <path d="M31.2189 54.5394C31.2189 54.4372 31.2189 54.2572 31.2189 53.9895V52.8653C31.2407 52.6187 31.179 52.3719 31.0437 52.1646C30.9727 52.0891 30.8853 52.0311 30.7883 51.9948C30.6912 51.9585 30.5872 51.945 30.4841 51.9553H30.0947V54.8752H28.9511V47.7165H30.6593C30.8839 47.6825 31.1134 47.7015 31.3295 47.7718C31.5456 47.8421 31.7423 47.9619 31.9039 48.1217C32.0655 48.2814 32.1876 48.4766 32.2605 48.6919C32.3333 48.9071 32.355 49.1364 32.3236 49.3614V49.9211C32.3236 50.6559 32.0803 51.1426 31.5888 51.381C31.8439 51.4818 32.0486 51.6795 32.1582 51.931C32.2854 52.2392 32.3451 52.571 32.3334 52.9043V54.0187C32.3334 54.2231 32.3334 54.3886 32.3334 54.5054C32.3491 54.6346 32.3835 54.7608 32.4356 54.8801H31.3017C31.2639 54.7693 31.2362 54.6552 31.2189 54.5394ZM30.0753 50.9333H30.5133C30.602 50.938 30.6907 50.9249 30.7743 50.8948C30.8579 50.8647 30.9346 50.8183 30.9999 50.7581C31.1294 50.5943 31.1904 50.3868 31.1703 50.179V49.4733C31.187 49.2754 31.1392 49.0773 31.034 48.9088C30.9794 48.8481 30.9114 48.801 30.8353 48.7714C30.7592 48.7419 30.6773 48.7306 30.596 48.7385H30.0753V50.9333Z" />
        <path d="M34.0659 48.0961C34.4086 47.7979 34.8476 47.6337 35.302 47.6337C35.7563 47.6337 36.1953 47.7979 36.5381 48.0961C36.8421 48.4714 36.9933 48.9476 36.9615 49.4296V49.6388H35.9005V49.342C35.9152 49.1512 35.8655 48.961 35.7594 48.8018C35.7063 48.7449 35.6411 48.7006 35.5688 48.672C35.4964 48.6434 35.4186 48.6312 35.3409 48.6363C35.2631 48.6301 35.185 48.6418 35.1124 48.6705C35.0399 48.6992 34.9749 48.7441 34.9224 48.8018C34.8121 48.9592 34.7604 49.1504 34.7764 49.342C34.7774 49.6246 34.8696 49.8994 35.0392 50.1255C35.2486 50.4089 35.4903 50.6669 35.7594 50.8944C36.0222 51.1377 36.2461 51.3567 36.4018 51.5416C36.5739 51.7471 36.7149 51.9766 36.8203 52.223C36.9417 52.5107 37.0014 52.8207 36.9955 53.133C37.0271 53.6165 36.8721 54.0938 36.5624 54.4664C36.2204 54.7719 35.7799 54.9439 35.3214 54.9511C34.8629 54.9582 34.4172 54.8 34.0659 54.5054C33.7579 54.132 33.6046 53.6548 33.6376 53.1719V52.7291H34.6985V53.2449C34.6985 53.7073 34.8932 53.936 35.2825 53.936C35.6718 53.936 35.8665 53.7073 35.8665 53.2449C35.8718 52.9629 35.7865 52.6866 35.6232 52.4565C35.4111 52.1754 35.1696 51.9176 34.9029 51.6876C34.6401 51.4443 34.4163 51.201 34.2605 51.0404C34.0868 50.8362 33.9456 50.6064 33.842 50.3591C33.7206 50.0713 33.6609 49.7613 33.6668 49.449C33.626 48.964 33.7684 48.4813 34.0659 48.0961Z" />
        <path d="M0 0V24.3911H2.56954V2.56954H61.4305V61.4305H2.56954V59.2697H0V64H64V0H0Z" />
    </svg>
);

export default Logo;
