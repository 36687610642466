import Paragraph from '@/components/paragraph';
import { Button } from '@/components/ui/button';
import { cn } from '@/shadcn-utils';
import { motion } from 'framer-motion';
import { ComponentProps, forwardRef } from 'react';

type ContactFormMessageProps = ComponentProps<'div'> & {
    onSendAnother?: () => void;
};

const ContactFormMessage = forwardRef<HTMLDivElement, ContactFormMessageProps>(
    ({ onSendAnother, className, ...props }, ref) => {
        return (
            <div
                {...props}
                ref={ref}
                className={cn(
                    'flex min-h-80 flex-col items-center justify-center gap-10',
                    className,
                )}
            >
                <div className="max-w-sm space-y-3 text-center">
                    <h3 className="text-lg font-medium">Dziękuję za kontakt!</h3>
                    <Paragraph className="text-sm text-muted-foreground">
                        Skontaktuję się z Państwem krótko po zapoznaniu się z jej treścią.
                        Standardowy czas oczekiwania to <b>1-2 dni</b> roboczych.
                    </Paragraph>
                </div>
                <div>
                    <Button type="button" variant="outline" onClick={() => onSendAnother?.()}>
                        Wyślij kolejną wiadomość
                    </Button>
                </div>
            </div>
        );
    },
);

export const MotionContactFormMessage = motion(ContactFormMessage);

export default ContactFormMessage;
