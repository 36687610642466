import { SanityCMSClient } from "@/api-clients/sanity-client";
import { HomePage } from "@/types/sanity.types";
import isNil from "@/utils/is-nil";

export const getHomePage = async (): Promise<HomePage> => {
    const query = `*[_type=="home-page"]{...,"pricing":{...pricing,"tiers":pricing.tiers[]->{...}}}`;
    const response = await SanityCMSClient.fetch<HomePage[]>(query);

    if (isNil(response) || response.length === 0) {
        throw new Error('No Data!');
    }

    return response[0];
};