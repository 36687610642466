import useScrollToElement from '@/hooks/use-scroll-to-element';
import { cn } from '@/shadcn-utils';
import { ArrowRightIcon } from 'lucide-react';
import { ComponentPropsWithoutRef } from 'react';

type NavbarButtonProps = ComponentPropsWithoutRef<'button'> & {
    sectionId: string;
    text: string;
    variant?: 'default' | 'highlighted';
};

const NavbarButton = ({
    sectionId,
    text,
    variant = 'default',
    onClick,
    ...props
}: NavbarButtonProps) => {
    const { scrollTo } = useScrollToElement();

    return (
        <button
            {...props}
            type="button"
            onClick={(event) => {
                onClick?.(event);
                scrollTo?.(sectionId);
            }}
            className={cn(
                'flex h-14 items-center justify-between rounded-sm px-4 text-sm font-medium lg:h-10 lg:justify-center lg:text-center',
                variant === 'default' &&
                    'bg-primary-foreground/5 text-primary-foreground hover:bg-primary-foreground/15 lg:bg-transparent',
                variant === 'highlighted' &&
                    'bg-primary-foreground text-primary hover:bg-primary-foreground/90',
            )}
        >
            {text}
            <ArrowRightIcon size={16} className="opacity-25 lg:hidden" />
        </button>
    );
};

export default NavbarButton;
