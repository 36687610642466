import { cn } from '@/shadcn-utils';
import { PricingTier } from '@/types/sanity.types';
import { ComponentPropsWithoutRef } from 'react';
import SanityRichText from './sanity-rich-text';

type PricingCardProps = ComponentPropsWithoutRef<'div'> & {
    tier: PricingTier;
};

const PricingCard = ({ tier, className, ...props }: PricingCardProps) => {
    return (
        <div
            className={cn(
                'relative cursor-default select-none rounded border-2 ring-primary/10 transition-all duration-300 hover:border-primary hover:ring-8',
                tier.isRecommended && 'border-4 border-primary shadow-lg',
                className,
            )}
            {...props}
        >
            {tier.isRecommended ? (
                <div className="absolute right-4 top-0 rounded-b bg-primary px-4 pb-2.5 pt-1">
                    <span className="text-xs font-semibold uppercase text-primary-foreground">
                        Sugerowany
                    </span>
                </div>
            ) : null}
            <div className="space-y-3 p-6">
                <div className="text-sm font-semibold capitalize">{tier.name}</div>
                <div className="flex items-center gap-2">
                    <span className="text-2xl/none font-medium">
                        {tier.price?.amount} {tier.price?.currency}
                    </span>
                    <span className="text-muted-foreground">
                        za m<sup>2</sup>
                    </span>
                </div>
            </div>
            <div className="border-t p-6">
                <div className="mb-4 text-sm font-semibold uppercase">Zawartość pakietu</div>
                <SanityRichText value={tier.details || []} />
            </div>
        </div>
    );
};

export default PricingCard;
