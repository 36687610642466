import { SanityCMSService } from "@/services/sanity-cms";
import { uiActions } from "@/store/general-slice";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useDispatch } from "./use-dispatch";

export const useHomePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return useSWR('home-page', () => {
        dispatch(uiActions.setIsLoading(true));
        return SanityCMSService.getHomePage();
    }, {
        dedupingInterval: 1800000,
        onSuccess: () => dispatch(uiActions.setIsLoading(false)),
        onError: () => {
            dispatch(uiActions.setIsLoading(false));
            navigate('/unavailable');
        },
    });
};
