import { EmpeApiClient } from "@/api-clients/empe-client";
import { MediaItem } from "@/models/media-item";

type GetMediaItemsResponse = {
    mediaItems: MediaItem[];
};

export const getMediaItems = async (albumId: string): Promise<MediaItem[]> => {
    const result = await EmpeApiClient.get<GetMediaItemsResponse>(`/google/albums/${albumId}`);

    return result.mediaItems;
};
