import { Button } from '@/components/ui/button';
import { motion, useMotionValueEvent, useScroll, Variants } from 'framer-motion';
import { ArrowUpIcon } from 'lucide-react';
import { MouseEventHandler, useState } from 'react';

const ScrollUpButton = () => {
    const [isScrolled, setIsScrolled] = useState<boolean>();
    const { scrollYProgress } = useScroll();

    useMotionValueEvent(scrollYProgress, 'change', (value) => {
        setIsScrolled(value >= 0.03 && value < 0.97);
    });

    const fadeInAnimation: Variants = {
        hidden: {
            opacity: 0,
            transform: 'translateY(50%)',
            transition: {
                duration: 0.25,
            },
        },
        visible: {
            opacity: 1,
            transform: 'translateY(0)',
            transition: {
                duration: 0.25,
            },
        },
    };

    const handleOnClick: MouseEventHandler<HTMLButtonElement> = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <motion.div
            initial="hidden"
            animate={isScrolled ? 'visible' : 'hidden'}
            variants={fadeInAnimation}
            viewport={{ once: true }}
            className="fixed bottom-12 right-12 z-10 shadow-lg"
        >
            <Button
                type="button"
                size="icon"
                title="Powrót do góry"
                variant="default"
                onClick={handleOnClick}
                className="size-12"
            >
                <ArrowUpIcon size={16} />
            </Button>
        </motion.div>
    );
};

export default ScrollUpButton;
