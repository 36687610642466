import { EmpeApiClient } from "@/api-clients/empe-client";
import { Album } from "@/models/album";

type GetAlbumsResponse = {
    albums: Album[];
    nextPageToken: string;
};

export const getAlbums = async (pageSize: number = 1): Promise<Album[]> => {
    const result = await EmpeApiClient.get<GetAlbumsResponse>(`/google/albums?pageSize=${pageSize}`);

    return result.albums;
};
