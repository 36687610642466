import Section from '@/components/section/section';
import PricingSectionHeader from './pricing-section-header';
import PricingSectionTiers from './pricing-section-tiers';

const PricingSection = () => {
    return (
        <Section id="pricing">
            <PricingSectionHeader />
            <PricingSectionTiers />
        </Section>
    );
};

export default PricingSection;
