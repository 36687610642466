import { useMediaItems } from '@/hooks/use-media-items';
import isNil from '@/utils/is-nil';
import { preloadImageAsync } from '@/utils/preload-image';
import { useCallback, useEffect, useState } from 'react';
import ProjectViewerContent from './project-viewer-content';

type ProjectViewerProps = {
    albumId: string;
};

const ProjectViewer = ({ albumId }: ProjectViewerProps) => {
    const { data: mediaItems, isLoading: isLoadingMediaItems } = useMediaItems(albumId);
    const [isPreloadingImages, setIsPreloadingImages] = useState<boolean>(false);

    const preloadImages = useCallback(async () => {
        if (isNil(mediaItems) || mediaItems.length === 0) {
            return;
        }

        setIsPreloadingImages(true);
        await Promise.all(mediaItems.map((item) => preloadImageAsync(`${item.baseUrl}=s80`)));
        setIsPreloadingImages(false);
    }, [mediaItems]);

    useEffect(() => {
        preloadImages();
    }, [preloadImages]);

    if (isNil(mediaItems) || isLoadingMediaItems || isPreloadingImages) {
        return <ProjectViewerContent.Skeleton />;
    }

    return <ProjectViewerContent mediaItems={mediaItems} />;
};

export default ProjectViewer;
