import { useHomePage } from '@/hooks/use-home-page';
import DefaultLayout from '@/layouts/default-layout';
import AboutSection from './components/about-section/about-section';
import ContactSection from './components/contact-section/contact-section';
import HeroSection from './components/hero-section';
import PricingSection from './components/pricing-section/pricing-section';
import ProjectsSection from './components/projects-section/projects-section';
import ReviewsSection from './components/reviews-section';

function HomePage() {
    useHomePage();

    return (
        <DefaultLayout>
            <HeroSection />
            <AboutSection />
            <ReviewsSection />
            <ProjectsSection />
            <PricingSection />
            <ContactSection />
        </DefaultLayout>
    );
}

export default HomePage;
