import { SanityCMSClient } from "@/api-clients/sanity-client";
import { Company } from "@/types/sanity.types";
import isNil from "@/utils/is-nil";

export const getCompany = async (): Promise<Company> => {
    const query = `*[_type=="company"]`;
    const response = await SanityCMSClient.fetch<Company[]>(query);

    if (isNil(response) || response.length === 0) {
        throw new Error('No Data!');
    }

    return response[0];
};