import * as Sentry from '@sentry/react';

export const initializeSentry = () => {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DNS,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllInputs: true,
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Tracing
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};
