import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';

type PageContainerProps = ComponentPropsWithoutRef<'div'>;

const PageContainer = ({ children, className, ...props }: PageContainerProps) => {
    return (
        <div className={cn('container flex flex-col gap-10', className)} {...props}>
            {children}
        </div>
    );
};

export default PageContainer;
