import { cn } from '@/shadcn-utils';
import { ComponentPropsWithoutRef } from 'react';

type ParagraphType = ComponentPropsWithoutRef<'p'>;

const Paragraph = ({ children, className, ...props }: ParagraphType) => (
    <p className={cn('max-w-prose text-base text-muted-foreground', className)} {...props}>
        {children}
    </p>
);

export default Paragraph;
