import Logo from '@/components/logo';
import NavbarButton from '@/components/navbar/navbar-button';
import PageContainer from '@/components/page-container';
import useScrollToElement from '@/hooks/use-scroll-to-element';
import { cn } from '@/shadcn-utils';
import { NavbarAction } from '@/types/navbar-actions';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import { MenuIcon, XIcon } from 'lucide-react';
import { useState } from 'react';

type NavbarState = 'default' | 'scrolled';

type NavbarMenuMobileProps = {
    actions: NavbarAction[];
};

const NavbarMenuMobile = ({ actions }: NavbarMenuMobileProps) => {
    const defaultActions = actions.filter((button) => button.type === 'default');
    const highlightedActions = actions.filter((button) => button.type === 'highlighted');

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [state, setState] = useState<NavbarState>('default');
    const { scrollTo } = useScrollToElement();
    const { scrollY } = useScroll();

    useMotionValueEvent(scrollY, 'change', (value) => {
        setIsOpened(false);
        setState(value > 0 ? 'scrolled' : 'default');
    });

    const handleActionClick = () => {
        setIsOpened(false);
    };

    const handleLogoClick = () => {
        scrollTo('root');
        handleActionClick();
    };

    return (
        <nav
            id="navbar"
            className={cn(
                'fixed inset-0 bottom-auto z-10 flex select-none flex-col lg:hidden',
                isOpened && 'bottom-0 backdrop-blur',
            )}
        >
            <div
                className={cn(
                    state === 'scrolled' || isOpened ? 'bg-primary' : '',
                    state === 'scrolled' ? 'py-6' : 'py-10',
                )}
            >
                <PageContainer>
                    <div className="flex items-center justify-between">
                        <button type="button" onClick={handleLogoClick}>
                            <Logo size={state === 'default' ? 64 : 48} />
                        </button>
                        <button
                            className="flex size-10 items-center justify-center text-primary-foreground"
                            onClick={() => setIsOpened((value) => !value)}
                        >
                            {isOpened ? <XIcon /> : <MenuIcon />}
                        </button>
                    </div>
                </PageContainer>
            </div>
            <div
                className={cn(
                    'border-t border-primary-foreground/15 bg-primary py-10 shadow-lg',
                    isOpened ? 'block' : 'hidden',
                )}
            >
                <PageContainer>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-2">
                            {defaultActions.map((navButton) => (
                                <NavbarButton
                                    key={navButton.elementId}
                                    sectionId={navButton.elementId}
                                    text={navButton.label}
                                    variant={navButton.type}
                                    onClick={handleActionClick}
                                />
                            ))}
                        </div>
                        <div className="flex flex-col gap-2">
                            {highlightedActions.map((navButton) => (
                                <NavbarButton
                                    key={navButton.elementId}
                                    sectionId={navButton.elementId}
                                    text={navButton.label}
                                    variant={navButton.type}
                                    onClick={handleActionClick}
                                />
                            ))}
                        </div>
                    </div>
                </PageContainer>
            </div>
        </nav>
    );
};

export default NavbarMenuMobile;
