import { EmpeApiClient } from "@/api-clients/empe-client";

type SendMailParams = {
    name: string;
    email: string;
    phone: string;
    message: string;
};

export const sendMail = async (params: SendMailParams) => {
    return await EmpeApiClient.post<SendMailParams, void>('/messaging/send-mail', {
        name: params.name,
        email: params.email,
        phone: params.phone,
        message: params.message,
    });
};
